.tab_bar_por {
  position: relative;
  width: 100%;
  height: 1.3rem;
}
.tab_bar_por .tab_bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.3rem;
  background: #fff;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.tab_bar_por .tab_bar a {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}
.tab_bar_por .tab_bar a i {
  color: #666;
  font-size: 0.5rem;
}
.tab_bar_por .tab_bar a span {
  color: #666;
  font-size: 0.3rem;
}
.tab_bar_por .tab_bar a.router-link-exact-active i {
  color: var(--themeColor);
  font-size: 0.5rem;
}
.tab_bar_por .tab_bar a.router-link-exact-active span {
  color: var(--themeColor);
  font-size: 0.3rem;
}
